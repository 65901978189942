@import './../../style/variables';

.startInfo {
    &TitleImg {
        width: 530px;
        display: flex;
        justify-content: flex-start;
    }
    &TvlWrapper {
        display: flex;
        justify-content: flex-start;
        margin-left: 57px;
        margin-top: 35px;
    }
    &Tvl {
        color: $tvlColor;
        font-size: 35px;
        &ValueWrapper {
            display: flex;
        }
    }
    &Tvb {
        color: $mainTextColor;
        font-size: 35px;
        display: flex;
        justify-content: flex-start;
        margin-left: 57px;
        margin-top: 10px;
    }
    &Phrase {
        position: absolute;
        width: auto;
        opacity: 0;
        font-size: 35px;
        color: $mainTextColor;
        margin-left: 397px;
        margin-top: -36px;
        padding-right: 20px;
        -webkit-text-stroke: 0.5px black;
        &Wrapper {
            text-align: start;
        }
        &First {
            display: inline-block;
            font-size: 35px;
            color: $mainTextColor;
            margin-left: 57px;
            margin-top: 20px;
            -webkit-text-stroke: 0.5px black;
        }
    }
}

.letter {
    display: inline-block;
    position: relative;
    float: left;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
}

.letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
    transform: rotateX(-90deg);
}

.letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media ( max-width: 1180px ) {
    .startInfo {
        &TitleImg {
            width: 430px;
        }
        &TvlWrapper {
            margin-left: 50px;
        }
        &Phrase {
            font-size: 30px;
            margin-left: 349px;
            margin-top: -32px;
            &First {
                font-size: 30px;
                margin-left: 47px;
            }
        }
        &Tvb {
            margin-left: 50px;
        }
    }
}

@media ( max-width: 900px ) {
    .startInfo {
        &TitleImg {
            width: 330px;
        }
        &TvlWrapper {
            margin-left: 38px;
        }
        &Tvl {
            font-size: 30px;
        }
        &Phrase {
            font-size: 25px;
            margin-left: 281px;
            margin-top: -26px;
            &First {
                font-size: 25px;
                margin-left: 37px;
            }
        }
        &Tvb {
            font-size: 30px;
            margin-left: 38px;
        }
    }
}

@media ( max-width: 670px ) {
    .startInfo {
        &TitleImg {
            width: 290px;
        }
        &TvlWrapper {
            margin-left: 34px;
        }
        &Tvl {
            font-size: 25px;
        }
        &Phrase {
            font-size: 22px;
            margin-left: 245px;
            margin-top: -23px;
            &First {
                font-size: 22px;
                margin-left: 32px;
            }
        }
        &Tvb {
            font-size: 25px;
            margin-left: 34px;
        }
    }
}

@media ( max-width: 550px ) {
    .startInfo {
        &TitleImg {
            width: 260px;
        }
        &TvlWrapper {
            margin-left: 31px;
        }
        &Tvl {
            font-size: 22px;
        }
        &Phrase {
            font-size: 20px;
            margin-left: 223px;
            margin-top: -21px;
            &First {
                font-size: 20px;
                margin-left: 29px;
            }
        }
        &Tvb {
            font-size: 22px;
            margin-left: 31px;
        }
    }
}

@media ( max-width: 400px ) {
    .startInfo {
        &Tvl {
            font-size: 19px;
        }
        &Phrase {
            font-size: 17px;
            margin-left: 195px;
            margin-top: -18px;
            &First {
                font-size: 17px;
            }
        }
    }
}