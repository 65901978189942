@import '../../style/variables';

.section2 {
    &Header {
        color: $mainTextColor;
        font-size: 3.5vw;
        &Wrapper {
            display: flex;
            justify-content: flex-start;
        }
        &Icon {
            width: 3.5vw;
            height: 3.5vw;
            margin-right: 0.6vw;
        }
    }
    &Line {
        width: 100%;
        margin-top: 50px;
        height: 3px;
        background: rgb(50,43,49);
        background: linear-gradient(90deg, rgba(50,43,49,1) 0%, rgba(50,43,49,1) 95%, rgba(0,0,0,1) 100%);
        &Active {
            width: 16.5%;
            height: 3px;
            background: rgb(0,0,0);
            background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        }
        &Circle {
            width: 0.8vw;
            height: 0.8vw;
            border: 2px solid $mainTextColor;
            border-radius: 50%;
            background-color: $mainTextColor;
            margin-top: -0.6vw;
            margin-left: -0.4vw;
        }
    }
    &Lines {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    &VerticalLine {
        width: 3px;
        height: 3vw;
        background-color: $greyLine;
        &Active {
            background-color: $mainTextColor;
        }
    }
    &Block {
        width: 26%;
        border: 7px solid $greyLine;
        border-radius: 2%;
        padding: 20px 10px 10px 10px;
        &Wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        &Active {
            border: 7px solid $mainTextColor;
        }
        &Title {
            font-size: 2.7vw;
            color: $orangeTextColor;
            display: flex;
            justify-content: flex-start;
            margin-left: 1vw;
        }
        &List {
            color: $mainTextColor;
            padding-inline-start: 1.5vw;
            li {
                text-align: left;
                font-size: 1.6vw;
            }
        }
    }
    // mobile
    &WrapperMobile {
        display: none;
        margin: 0 auto;
        justify-content: center;
        height: 1200px;
        margin-top: 30px;
    }
    &LineMobile {
        height: 100%;
        width: 3px;
        background: rgb(50,43,49);
        background: linear-gradient(180deg, rgba(50,43,49,1) 0%, rgba(50,43,49,1) 95%, rgba(0,0,0,1) 100%);
        &Active {
            height: 180px;
            width: 3px;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        }
        &Circle {
            width: 15px;
            height: 15px;
            border: 2px solid $mainTextColor;
            border-radius: 50%;
            background-color: $mainTextColor;
            margin-top: -8px;
            margin-left: -11px;
        }
    }
    &LinesMobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 177px;
        margin-bottom: 177px;
    }
    &HorizontalLineMobile {
        width: 50px;
        height: 3px;
        background-color: $greyLine;
        &Active {
            background-color: $mainTextColor;
        }
    }
    &BlockWrapperMobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &BlockMobile {
        width: 300px;
        height: 310px;
        border: 7px solid $greyLine;
        border-radius: 2%;
        padding: 20px 10px 10px 10px;
    }
    &BlockActiveMobile {
        border: 7px solid $mainTextColor;
    }
    &BlockTitleMobile {
        font-size: 30px;
        color: $orangeTextColor;
        display: flex;
        justify-content: flex-start;
        margin-left: 25px;
    }
    &BlockListMobile {
        color: $mainTextColor;
        padding-inline-start: 25px;
        li {
            text-align: left;
            font-size: 20px;
            margin-bottom: 2px;
        }
    }
}

@media ( max-width: 800px ) {
    .section2 {
        &Header {
            font-size: 27px;
            &Icon {
                width: 25px;
                height: 25px;
            }
        }
        &Line {
            display: none;
        }
        &Lines {
            display: none;
        }
        &BlockWrapper {
            display: none;
        }
        &WrapperMobile {
            display: flex;
        }
    }
}

@media ( max-width: 500px ) {
    .section2 {
        &WrapperMobile {
            height: 1020px;
        }
        &LinesMobile {
            margin-top: 157px;
            margin-bottom: 157px;
        }
        &LineMobile {
            &Active {
                height: 160px;
            }
            &Circle {
                width: 12px;
                height: 12px;
                margin-top: -6px;
                margin-left: -9px;
            }
        }
        &BlockMobile {
            width: 245px;
            height: 270px;
        }
        &BlockTitleMobile {
            font-size: 27px;
        }
        &BlockListMobile {
            li {
                font-size: 17px;
            }
        }
    }
}

@media ( max-width: 420px ) {
    .section2 { 
        &WrapperMobile {
            height: 910px;
        }
        &LinesMobile {
            margin-top: 140px;
            margin-bottom: 140px;
        }
        &LineMobile {
            &Active {
                height: 143px;
            }
            &Circle {
                width: 12px;
                height: 12px;
                margin-top: -6px;
                margin-left: -9px;
            }
        }
        &HorizontalLineMobile {
            width: 35px;
        }
        &BlockMobile {
            width: 215px;
            height: 235px;
        }
        &BlockListMobile {
            li {
                font-size: 14px;
            }
        }
    }
}

@media ( max-width: 350px ) {
    .section2 {
        &HorizontalLineMobile {
            width: 20px;
        }
    }
}