$mainTextColor: #fff;
$yellowTextColor: #fbbb0a;
$orangeTextColor: #f9720d;
$redTextColor: #ff3131;
$mainFont: 'alagard';
$skeletonBg: #424242;
$bgColor: #000;
$greyLine: #322b31;
$tvlColor: #acff7d;

// $phraseColor1: #acff7d;
// $phraseColor2: #f3ff7d;
// $phraseColor3: #a4d7ff;
// $phraseColor4: #ffabf6;
// $phraseColor5: #ffabab;
