@import '../../style/variables';

.timer {
    &Wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $mainTextColor;
        font-size: 14px;
        -webkit-text-stroke: 0.25px black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 310px;
    }
    &Text {
        margin-right: 5px;
        text-transform: uppercase;
    }
    &CardContentInner {
        width: 55px;
    }
    &Countdown {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    &ReloadMsg {
        text-transform: uppercase;
    }
}


@media ( max-width: 650px ) {
    .timer {
        &Wrapper {
            font-size: 10px;
            top: 3px;
            right: 3px;
            width: 230px;
        }
        &Text {
            margin-right: 0px;
        }
    }
}

@media ( max-width: 450px ) {
    .timer {
        &Wrapper {
            display: none;
        }
    }
}