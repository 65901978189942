.mainFrame {
    border-image-source: url("../img/frame-windows-2-225.png") !important;
    border-image-slice: 12 !important;
    border-image-repeat: repeat !important;    
    border-image-width: auto !important;
}
  
.mb35 {
    margin-bottom: 35px !important;
}

@media ( max-width: 800px ) {
    .mb35 {
        margin-bottom: 20px !important;
    }
}

.yellowText {
    color: $yellowTextColor
}
.orangeText {
    color: $orangeTextColor
}
.redText {
    color: $redTextColor
}

.clickImgText {
    margin: 0 auto;
    color: #6e6e6e;
}

.hidden {
    opacity: 0;
}

// .phraseColor1 {
//     color: $phraseColor1;
// }
// .phraseColor2 {
//     color: $phraseColor2;
// }
// .phraseColor3 {
//     color: $phraseColor3;
// }
// .phraseColor4 {
//     color: $phraseColor4;
// }
// .phraseColor5 {
//     color: $phraseColor5;
// }

@media ( max-width: 500px ) {
    .clickImgText {
        font-size: 13px;
    }
}