@import './../../style/variables';

.footer {
    &Wrapper {
        height: 2.6vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 10vw;
    }
    &Text {
        color: $mainTextColor;
        font-size: calc(13px + 0.3vw);
    }
    &SocialNetworkWrapper {
        width: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 0.3vw;
    }
    &Link {
        margin-right: 1vw;
        img {
            width: calc(24px + 0.3vw);
        }
    }
}

@media ( max-width: 1300px ) {
    .footer {
        &Wrapper {
            margin-bottom: 10px;
        }
        &Link {
            margin-right: 15px;
            img {
                width: 28px;
            }
        }
    }
}

@media ( max-width: 1000px ) {
    .footer {
        &Wrapper {
            margin-top: 130px;
        }
    }
}

@media ( max-width: 700px ) {
    .footer {
        &Link {
            img {
                width: 25px;
            }
        }
    }
}

@media ( max-width: 650px ) {
    .footer {
        &Wrapper {
            flex-direction: column;
            justify-content: center;
            flex-wrap: nowrap;
            height: 100px;
            margin-bottom: 0;
        }
        &Text {
            font-size: 15px;
            width: 170px;
            line-height: 1.6;
        }
        &SocialNetworkWrapper {
            margin-top: 20px;
            width: 250px;
        }
        &Link {
            margin-right: 0;
        }
    }
}