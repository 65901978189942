@import '../../style/variables';

.sectionEnd {
    &Header {
        color: $mainTextColor;
        font-size: 3.5vw;
        margin-top: 7vw;
    }
    &Btn {
        width: 13vw;
        font-family: $mainFont !important;
        font-size: 1.1vw !important;
        svg {
            font-size: 1.6vw !important;
            margin-bottom: 0.15vw !important;
        }
        &Wrapper {
            display: flex;
            justify-content: space-between;
            width: 30vw;
            margin: 0 auto;
            margin-top: 2vw;
        }
    }
    &Img {
        width: 14.5vw;
        &Wrapper {
            margin-top: 3vw;
        }
    }
}

@media ( max-width: 1000px ) {
    .sectionEnd {
        &Header {
            font-size: 50px;
        }
        &Btn {
            width: 240px;
            font-size: 18px !important;
            &Wrapper {
                width: 540px;
                margin-top: 30px;
            }
        }
        &Img {
            width: 200px;
            &Wrapper {
                margin-top: 30px;
            }
        }
    }
}

@media ( max-width: 700px ) {
    .sectionEnd {
        &Header {
            font-size: 45px;
        }
        &Btn {
            width: 200px;
            font-size: 18px !important;
            &Wrapper {
                width: 440px;
                margin-top: 30px;
            }
        }
        &Img {
            width: 200px;
            &Wrapper {
                margin-top: 30px;
            }
        }
    }
}

@media ( max-width: 550px ) {
    .sectionEnd {
        &Btn {
            width: 200px;
            font-size: 18px !important;
            &Wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 120px;
                margin-top: 50px;
            }
        }
        &Img {
            &Wrapper {
                margin-top: 50px;
            }
        }
    }
}