@import '../../style/variables';

.section4 {
    &Header {
        color: $mainTextColor;
        font-size: 3vw;
        text-align: left;
        display: flex;
        align-items: center;
        &Wrapper {
            display: flex;
            justify-content: flex-start;
        }
        &Icon {
            width: 3.5vw;
            height: 3.5vw;
            margin-right: 0.6vw;
        }
    }
    &Wrapper {
        display: flex;
        justify-content: center;
        margin-top: 2.6vw;
    }
    &Img {
        width: 100%;
        &Wrapper {
            width: 50%;
            &1 {
                margin-right: 6.2vw;
            }
        }
    }
}

@media ( max-width: 800px ) {
    .section4 {
        &Wrapper {
            flex-direction: column;
        }
        &Img {
            &Wrapper {
                width: 100%;
                &1 {
                    margin-bottom: 40px;
                }
            }
        }
    }
}

@media ( max-width: 600px ) {
    .section4 {
        &Header {
            font-size: 24px;
            &Icon {
                width: 25px;
                height: 25px;
            }
        }
        &Img {
            &Wrapper {
                &1 {
                    margin-bottom: 20px;
                }
            }
        }
    }
}