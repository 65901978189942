@import '../../style/variables';

.scrollBtns {
    &Wrapper {
        display: flex;
        justify-content: space-around;
        margin-top: -4.5vw;
        margin-bottom: 30px;
    }
    &Arrow {
        width: 90px;
        animation: slide-bottom 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;
    }
}

@media ( max-width: 1000px ) {
    .scrollBtns {
        &Wrapper {
            margin-top: -9vw;
            justify-content: space-between;
            padding: 0 30px 0 30px;
        }
    }
}

@media ( max-width: 660px ) {
    .scrollBtns {
        &Wrapper {
            margin-top: -12vw;
        }
    }
}

@media ( max-width: 450px ) {
    .scrollBtns {
        &Wrapper {
            margin-bottom: 20px;
        }
        &Arrow {
            width: 75px;
        }
    }
}

@keyframes slide-bottom {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0);
    }
}