@import '../../style/variables';

.sectionVideo {
    &Header {
        color: $mainTextColor;
        font-size: 3.5vw;
        text-align: left;
        &Wrapper {
            display: flex;
            justify-content: flex-start;
        }
        &Icon {
            width: 3.5vw;
            height: 3.5vw;
            margin-right: 0.6vw;
        }
    }
    &Wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    &Frame {
        margin-top: 3.5vw;
    }
}

@media ( max-width: 850px ) {
    .sectionVideo {
        &Frame {
            width: 560px;
            height: 315px;
        }
    }
}

@media ( max-width: 680px ) {
    .sectionVideo {
        &Frame {
            width: 448px;
            height: 252px;
        }
    }
}

@media ( max-width: 520px ) {
    .sectionVideo {
        &Frame {
            width: 314px;
            height: 176px;
        }
    }
}

@media ( max-width: 390px ) {
    .sectionVideo {
        &Frame {
            width: 255px;
            height: 145px;
        }
    }
}