@import './../../style/variables';

.main {
    &Buttons {
        display: flex;
        justify-content: center;
        margin-top: 5.5vw;
        margin-bottom: 55px;
        &Wrapper {
            width: 450px;
        }
        &Text {
            margin-bottom: 30px;
            font-size: 23px;
            color: $mainTextColor;
            -webkit-text-stroke: 0.5px black;
        }
    }
    &Btn {
        min-width: 13vw !important;
        font-family: $mainFont !important;
        font-size: 20px !important;
    }
    &LinksWrapper {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
    }
    &NetImg {
        width: 90px;
        height: 90px;
    }
    &Skeleton {
        background-color: $skeletonBg !important;
        display: inline-block !important;
    }
}

@media ( max-width: 900px ) {
    .main {
        &Buttons {
            margin-top: 100px;
        }
        &Btn {
            font-size: 16px !important;
        }
        &NetImg {
            width: 60px;
            height: 60px;
        }
    }
}

@media ( max-width: 670px ) {
    .main {
        &Buttons {
            margin-top: 70px;
            &Wrapper {
                width: 300px;
            }
        }
        &Btn {
            font-size: 14px !important;
        }
        &NetImg {
            width: 45px;
            height: 45px;
        }
    }
}

@media ( max-width: 450px ) {
    .main {
        &Buttons {
            margin-bottom: 85px;
        }
        &ButtonsWrapper {
            width: 270px;
        }
        &Btn {
            font-size: 12px !important;
            width: 160px !important;
        }
        &NetImg {
            width: 35px;
            height: 35px;
        }
    }
}