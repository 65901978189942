@import '../../style/variables';

.main {
    &ParallaxContainer {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        justify-content: flex-start;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
    }
    &Content {
        &Section {
            padding: 6.2vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &End {
                padding-bottom: 1vw;
            }
        }
    }
    &ParallaxContainer1 {
        min-height: 300px;
        background-image: url("../../img/parallax-bg-1.png");
    }
    &ParallaxContainer2 {
        min-height: 300px;
        background-image: url("../../img/parallax-bg-2.png");
    }
    &ParallaxContainer3 {
        min-height: 300px;
        background-image: url("../../img/parallax-bg-end.png");
    }
    &ParallaxContainer4 {
        min-height: 300px;
        background-image: url("../../img/parallax-bg-3.png");
    }
    &ParallaxContainer5 {
        min-height: 300px;
        background-image: url("../../img/parallax-bg-4.png");
    }
}
.content {
    flex: 1 1 auto;
    position: relative;
}

@media ( max-width: 1000px ) {
    .main {
        &ParallaxContainer1, &ParallaxContainer2, &ParallaxContainer3, &ParallaxContainer4 {
            min-height: 200px;
        }
        &ContentSection {
            padding: 45px;
            &End {
                padding-bottom: 20px;
            }
        }
    }
}

@media ( max-width: 600px ) {
    .main {
        &ContentSection {
            padding: 35px;
            &End {
                padding-bottom: 20px;
            }
        }
    }
}

@media ( max-width: 500px ) {
    .main {
        &ParallaxContainer1, &ParallaxContainer2, &ParallaxContainer3, &ParallaxContainer4 {
            min-height: 170px;
        }
    }
}

@media ( max-width: 400px ) {
    .main {
        &ContentSection {
            padding: 25px;
        }
    }
}