@import '../../style/variables';

.section1 {
    &Header {
        color: $mainTextColor;
        font-size: 3.5vw;
        text-align: left;
        &Wrapper {
            display: flex;
            justify-content: flex-start;
        }
        &Icon {
            width: 3.5vw;
            height: 3.5vw;
            margin-right: 0.6vw;
        }
    }
    &Wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 2.6vw;
    }
    &Text {
        color: $mainTextColor;
        font-size: 2vw;
        text-align: left;
        line-height: 1.4;
        &Wrapper {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin-right: 6.2vw;
        }
    }
    &List {
        color: $mainTextColor;
        font-size: 2vw;
        text-align: left;
        line-height: 1.4;
    }
    &Img {
        width: 100%;
        &Wrapper {
            width: 50%;
        }
    }
}

@media ( max-width: 1000px ) {
    .section1 {
        &Text {
            &Wrapper {
                margin-right: 45px;
            }
        }
    }
}

@media ( max-width: 800px ) {
    .section1 {
        &Wrapper {
            flex-direction: column;
        }
        &Text {
            &Wrapper {
                width: 100%;
                margin-right: 0;
            }
        }
        &Img {
            &Wrapper {
                margin-top: 30px;
                width: 100%;
            }
        }
    }
}

@media ( max-width: 600px ) {
    .section1 {
        &Header {
            font-size: 24px;
            &Icon {
                width: 25px;
                height: 25px;
            }
        }
        &Wrapper {
            margin-top: 30px;
        }
        &Text {
            font-size: 14px;
        }
        &List {
            font-size: 14px;
        }
    }
}