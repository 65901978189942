@import './style/variables';
@import './style/_fonts';
@import './style/_commonClasses';

body {
  margin: 0;
  font-family: $mainFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bgColor;
  background-size: cover;
  background-position: center center;
  // background-attachment: fixed;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}